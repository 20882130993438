.Black {
  color: #352d2d;
}

.Red {
  color: #f2451d;
}

.Blue {
  color: #5353d5;
}

.Yellow {
  color: #fd0;
}

.smiley {
  color: rgb(56, 128, 20);
}

h2, button {
  font-family: "Gill Sans";
}

.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.App-header {
  border-bottom: 3px solid #e1b69d;
  background-color: #442e2e;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header h1 {
  font-family: Baskerville, Georgia, Times, 'Times New Roman', serif;
  font-weight: bolder;
  font-size: calc(10px + 7vmin);
  text-shadow: 3px 3px 0px #086f56;
  color: #f2ce8a;
}

.App-link {
  color: #61dafb;
}

#CommunityBoardAndChat {
  display: flex;
  flex-grow: 1;
}

#CommunityBoard-wrapper {
  width: 80%;
  display: flex;
  height: 100%;
  justify-content: center;
  background-color: #1b2f11;
}

#CommunityBoard {
  /* height: 60vw; */
  /* width: 100%; */
  /* This way all players see the same size board and tiles (proportional to their viewport, of course). */
  aspect-ratio: 16/9;
  /* aspect-ratio only sets the "preferred" ratio. We need this max-width to enforce it for wide viewports. */
  /* max-width: 107vh; */
  /* And after I put a wrapper element around this one, aspect-ratio pretty much seemed to stop working entirely. So we need this as well. */
  /* And then I broke it again by putting in the chat box and gave up and fixed with with JS instead. */
  /* max-height: 60vw; */
  background: radial-gradient(circle, transparent 80%, #333 150%), #260;
  color: white;
}

#CommunityBoard.max {
  width: auto;
  height: 100%;
}

#CommunityBoard h2 {
  position: absolute;
  padding-top: 0.2em;
  padding-left: 1em;
}

#CommunityBoard-board {
  position: relative;
  height: 100%;
}

#Chat-wrapper {
  width: 20vw;
  /* For some reason, this keeps the chat section from growing too
     large and making the page overflow the viewport. */
  display: flex;
}

#Chat {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  background-color: #eee;
}

#Chat-messages {
  height: 0; /* CSS is crazy. This is also needed to keep the chat section
                from growing too large and making the page overflowing the
                viewport. */
  flex-grow: 1;
  overflow: scroll;
  margin-bottom: 0.5em;
}

.Chat-message-event {
  font-weight: bold;
  color: rgb(25, 25, 145);
}

.Chat-message-event.system-event {
  color: rgb(126, 22, 22);
}

.Chat-message-author {
  font-weight: bold;
}

#Chat-input {
  display: flex;
}

#Chat-input-text {
  width: 100%;
  margin-right: 2px;
}

#JoinGame {
  text-align: center;
  background-color: #ca7;
  padding: 3em;
}

#username-field {
  font-size: 1.2em;
}

#username {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

#PlayerTiles {
  background-color: #ca7;
  position: relative;
  padding-bottom: 10px;
}

#PlayerTiles h2 {
  /* position: absolute; */
  padding-top: 0.2em;
  padding-left: 1em;
}

#PlayerTiles-buttons button {
  margin-left: 0.5em;
}

.PlayerTiles-rack {
  /*margin-left: 20vw;
  margin-right: 20vw;*/
  /* padding: 1em; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 72%;
  margin: auto;
}

.Tile {
  /* display: inline-block; */
  /* width: 1.9em; */
  /* margin: 0.2rem; */
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
  /*border: 2px solid black;*/
  border-radius: 6px;
  box-shadow: 
    3px 3px 4px 0 rgba(0, 0, 0, 0.25),
    3px 3px 4px 0 rgba(255, 255, 255, 0.3) inset,
    -2px -2px 3px 0 rgba(0, 0, 0, .25) inset;
  background-color: #fff0ee;
  cursor: grab;
  font-weight: bold;
  /* font-size: 3em; */
  text-align: center;
  text-shadow: 1px 1px 1px #555; /*rgba(255,255,255,0.5);
  /*-webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;*/

  width: 4.5vmin;
  height: 5.5vmin;
  font-size: 2.8vmin;
  margin: 0.3vmin;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 0.3vmin;
}

/* Put a dot above the tile to indicate it's new. */
.Tile.new::before {
  content: "•";
  position: absolute;
  font-size: 1.5em;
  color: rgb(10, 168, 57);
  top: -46%;
  left: 5%;
  pointer-events: none;
  text-shadow: none;
}

.Tile.new {
  position: relative;
  animation: new-tile-glow 1s infinite alternate;
  background-color: #f7f0d8;
}

@keyframes new-tile-glow {
  from {
    background-color: #f0f6c6;
  }
  to {
    background-color: #f7f0d8;
  }
}

.new-game, #PlayerTiles-buttons {
  position: absolute;
  top: 0.6em;
  right: 1em;
}